<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <currency-select
      v-model="textData"
      class="w-full"
      :disabled="disabled"
      :placeholder="filter.placeholder"
      :allowed-currencies="filter.options"
      @update:modelValue="handleFilterInput"
      @clear="handleFilterInput"
    />
  </div>
</template>

<script>
import CurrencySelect from '@/components/currency-select'
import typeOf from 'just-typeof'
export default {
  components: {
    CurrencySelect
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    tags: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String],
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: this.modelValue || null
    }
  },
  computed: {
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        const isDisabled = disable(this.tags)
        if (isDisabled) {
          if (this.textData) {
            this.setToNull()
          }
          return isDisabled
        }
      }
      return false
    }
  },
  methods: {
    handleFilterInput(v) {
      this.$emit('update:modelValue', this.textData || null)
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    }
  }
}
</script>

<style scoped>
.flex :deep(.el-select) {
  width: 100%;
}
</style>
